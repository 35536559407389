/* _________________________ FONTS FAMILIES _____________________ */

/* _________________________ BASIC HTML STANDARD _____________________ */

:root {
    box-sizing: border-box;
    --colorprimary: #ffffff;
    --colorsecondary: #1d00e7;
    --marginfooter: 2em;
    --marginfootermobile: 1em;
    --marginbuttons: 0.6em;
    --sizebuttons: 40px;
    --sizebuttonsmobile: 32px;
    --maintransition: all .1s ease-in-out;
}


html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Inconsolata', monospace;
    color: var(--colorprimary);
    text-transform: uppercase;
    font-size: 1em;
    text-align: center;
    line-height: 1.2;
    overflow: hidden;
    box-sizing: border-box;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: 1em !important;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--colorprimary);
    /* background-color: green; */
}

p {
    margin: 0;
    padding: 0;

}

span {
    display: inline-block;
    text-decoration: none;
    /* vertical-align: middle; */
    /* line-height: normal; */
}

/* _________________________ NAVIGATION _____________________ */
.navigation-wrap {
    position: absolute;
    width: 100vw;
    justify-content: center;
    display: flex;
    align-content: center;
    /* background-color: red; */
}

.boutons-wrap {
    margin: .2em;
    justify-content: space-between;
    display: flex;
    align-content: center;
    width: 100%;
}

.button {
    border: 1px solid var(--colorprimary);
    /* padding: .4em .7em .4em .7em; */
    transition: var(--maintransition);

}

#contact .button {
    height: var(--sizebuttons);
    padding: 0px 10px;
}

.button:hover {
    border: 1px solid var(--colorsecondary);
    background-color: var(--colorsecondary);
    color: var(--colorprimary);
    /* box-shadow: 0 3px 2px 0px black; */
    /* cursor: pointer; */
    transition: var(--maintransition);
}

.button a {
    color: var(--colorprimary);
    height: 100%;
    width: 100%;
    /* background-color: yellow; */
    transition: var(--maintransition);
}

.button a:hover {
    color: var(--colorprimary);
    transition: var(--maintransition);
}

span:hover {
    background-color: var(--colorsecondary);
    transition: var(--maintransition);
}

#socials .button {
    height: var(--sizebuttons);
    width: var(--sizebuttons);
    display: flex;
    justify-content: center;
    align-items: center;
}

#cv {
    margin-left: 0.6em;
}

#email {
    margin-right: 0.6em;
}

.vertical-lines {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: var(--colorprimary);
    opacity: 0.15;
}

.vertical-lines {
    left: 25%;
}

.vertical-lines:nth-child(2) {
    left: 50%;
}

.vertical-lines:nth-child(3) {
    left: 75%;
}

/* _________________________ SECTION _____________________ */

#section-titles {
    position: fixed;
    width: auto;
    height: auto;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-content: center;
    /* background-color: var(--colorsecondary); */
}

#logo {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    top: 45%;
}

#section-wip {
    position: fixed;
    height: auto;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-content: center;
    /* background-color: var(--colorsecondary); */
}

/* _________________________ Footer _____________________ */
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    /* background-color: var(--colorsecondary); */
}

#divider-footer {
    border-top: 1px solid var(--colorprimary);
    border-left: 1px solid var(--colorprimary);
    border-right: 1px solid var(--colorprimary);
    height: 10px;
    position: relative;
    margin: 0px var(--marginfooter) 0px var(--marginfooter);
}



/* _________________________ MEDIA QUERIES _____________________ */


@media only screen and (min-width:1450px) {
    #divider-top-container {
        position: fixed;
        width: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 3%;
        /* margin: 0px var(--marginfooter) 0px var(--marginfooter); */
    }
    
    #divider-top {
        border-top: 1px solid var(--colorprimary);
        border-left: 1px solid var(--colorprimary);
        border-right: 1px solid var(--colorprimary);
        height: 10px;
        width: 100%;
        /* margin: 0px var(--marginfooter) 0px var(--marginfooter); */
    }


    .lines:first-child {
        left: 10%;
    }
    
    .lines:nth-child(2) {
        left: 12%;
    }
    
    .lines:nth-child(3) {
        left: 18%;
    }
    
    .lines:nth-child(4) {
        left: 35%;
    }
    
    .lines:nth-child(5) {
        left: 50%;
    }
    
    .lines:nth-child(6) {
        left: 70%;
    }
    
    .lines:nth-child(7) {
        left: 78%;
    }
    
    .lines:nth-child(8) {
        left: 80%;
    }
    
    .lines:nth-child(9) {
        left: 98%;
    }
    
    .lines {
        height: 100%;
        position: absolute;
        width: 1px;
        background-color: var(--colorprimary);
    }

    #section-titles{
        width: auto;
    }


    #content-footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.5em;
        padding: 20px 0px 20px 0px;
        margin: 0px var(--marginfooter) 0px var(--marginfooter);
        /* background-color: turquoise; */
    }

    #contact {
        grid-column-start: span 3;
        display: flex;
    }

    #description {
        grid-column-start: span 6;
    }

    #location {
        grid-column-start: span 2;
    }

    #socials {
        grid-column-start: span 1;
        display: flex;
        justify-content: right;
    }

    #logo{
    max-width: 1600px;
    }
}

@media only screen and (max-width:1450px) {
    #content-footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.5em;
        padding: 20px 0px 20px 0px;
        margin: 0px var(--marginfooter) 0px var(--marginfooter);
    }


    #contact {
        /* background-color: red; */
        grid-column-start: span 4;
        display: block;
    }

    #description {
        /* background-color: yellow; */
        grid-column-start: span 5;
    }

    #location{
        grid-column-start: span 2;
    }

    #socials{
        display: flex;
        justify-content: right;
    }

    #contact .button {
        padding: 0px 6px;
        width: max-content;
    }
}


@media only screen and (max-width:900px) {

    html,
    body {
        font-size: 0.95em;
    }

    #socials,
    #contact {
        justify-content: space-between;
        display: flex;
    }

    #socials .button {
        height: var(--sizebuttonsmobile);
        width: var(--sizebuttonsmobile);
    }

    #contact .button {
        height: var(--sizebuttonsmobile);
        padding: 0px 6px;
    }

    #divider-top-container {
        width: 90%;
        top: 1.2em;
        display: none;
    }

    #logo {
        width: 90vw;
        top: 18%;
    }

    #section-wip {
        bottom: 35%;
    }

    #section-titles {
        width: 90%;
        top: 3em;
    }

    #divider-footer {
        margin: 0px var(--marginfootermobile) 0px var(--marginfootermobile);
        height: 8px;
    }

    .lines {
        display: none;
    }


    #content-footer {
        display: block !important;
        margin: 0px var(--marginfootermobile) 0px var(--marginfootermobile);
        padding: 15px 0px 10px 0px;
        /* background-color: turquoise; */
    }
}

@media only screen and (max-width:300px) {
    #content-footer {
        display: block;
        /* background-color: red; */
    }

    #contact {
        display: inline-block;
    }

    #contact .button {
        display: inline-block;
    }
}